<template>
  <v-app>
    <v-row justify="center">
      <v-col
        cols="12"
        lg="7"
      >
        <base-material-card
          title="Tulis Pesan"
          icon="mdi-message"
        >
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-text-field
                  v-model="pesan"
                  label="Tulis Pesan"
                  placeholder="Masukan Pesan"
                />
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="(data, i) in to"
              :key="i"
            >
              <v-list-item-content>
                <v-text-field
                  v-model="kepada[i]"
                  label="Kirim Kepada"
                />
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  icon
                  color="primary"
                  @click="addMsg"
                >
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-action>
                <v-btn
                  icon
                  color="red"
                  @click="removeMsg(i)"
                >
                  <v-icon>
                    mdi-close-octagon
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-select
                  v-model="type"
                  :items="items"
                  label="Standard"
                />
              </v-list-item-content>
            </v-list-item>
            <div class="d-flex justify-end mr-3">
              <v-btn
                icon
                color="info"
                @click="sendMsg"
              >
                <v-icon>
                  mdi-send
                </v-icon>
              </v-btn>
            </div>
          </v-list>
        </base-material-card>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = 'https://nc.notiva.net'
  axios.defaults.headers.common.Authorization =
    'Bearer ' + localStorage.getItem('access_token')
  export default {
    name: 'CreateMsg',
    data: () => ({
      pesan: '',
      to: [''],
      items: ['personal', 'broadcast'],
      type: '',
      kepada: [],
    }),
    methods: {
      sendMsg () {
        axios
          .post('/v1/user/message', {
            text: this.pesan,
            to: this.kepada,
            type: this.type,
          })
          .then(response => {
            if (response.data.meta.status) {
              this.$router.push({ path: '/pesan' })
              this.$toast.success('Berhasil Di Sunting', {
                type: 'success',
                position: 'top-right',
                duration: 3000,
                dismissible: true,
              })
            }
          })
      },
      addMsg () {
        this.to.push('')
      },
      removeMsg (index) {
        if (this.to.length > 1) {
          this.to.splice(index, 1)
        }
      },
    },
  }
</script>

<style></style>
